import React, { useState, useEffect } from "react";
import { MenuItem, Select, Button, FormControl } from "@material-ui/core";
import {ImWhatsapp} from 'react-icons/im';
import { bcAlert } from "../../../../boclAlert";
import axios from "axios";
import "./Calculadora.css";
import ReactGA from "react-ga";
import RowInput from "../Inputs/RowInput";
import HeaderCotizador from "../Header/HeaderCotizador";
import debounce from "lodash/debounce";
import SliderInput from "../Inputs/SliderInput/SliderInput";

const Calculadora = ({
  origen_mail = "arrendadoradatamovil",
  origen = "arrendadoradatamovil"
}) => {
  const [sliderChange, setSliderChange] = useState(false)
  /*--STYLES
  -------Uses @material-ui
  -------Brandon Cantu*/
  const paperStyle = {
    padding: 30,
    height: "auto",
    width: 550,
    // maxWidth: "90%",
    paddingTop: "30px",
  };
  const btnstyle = { margin: ".8rem 0 1rem 0" };

  /*VARIABLES AND CONST__________________
  _____________________________________*/

  /*--Declaration of INPUTS VARIABLES
  -------Brandon Cantu*/
  const [datosCotizar, setDatosCotizar] = useState({
    precio_vehiculo: 275000,
    precio_seguro: "7,500.00",
    // enganche: "7,505.5",
    enganche: "9,680.5",
    uso_auto: 1,
    tipo_auto: 2,
    plazos: 120,
  });
  const [datosCotizarSend, setDatosCotizarSend] = useState({
    Send_precio_vehiculo: 275000,
    Send_precio_seguro: 7500,
    Send_uso_auto: 1,
    Send_tipo_auto: 2,
    Send_plazos: 120,
  });
  // SE QUITA EL SLIDER VEHICULO const [Send_precio_vehiculo, setSend_precio_vehiculo] = useState(150000.00);
  const [Send_enganche, setSend_enganche] = useState(7505.5);

  const [datosPersona, setDatosPersona] = useState({
    nombre_persona: "",
    auto: "",
    correo: "",
    celular: "",
  });
  const [infoEnviada, setInfoEnviada] = useState(false);
  const [politicas, setPoliticas] = useState({
    Politica_precio_vehiculo: 100000,
    // Politica_max_precio_vehiculo: 1500000,
    Politica_max_precio_vehiculo: 400000,
    Politica_precio_seguro: 7500,
    Politica_enganche: 7505.5,
    Politica_max_enganche: 182285.5,
    Politica_plazos: 0,
  });

  const [resultadoCalculo, setResultadoCalculo] = useState(
    datosCotizar.precio_vehiculo - datosCotizar.enganche
  );
  const [resultadoCalculoError, setResultadoCalculoError] = useState("");
  const [plazoText, setPlazoText] = useState("Pago Quincenal");
  const [dosYMedioUno, setDosYMedioUno] = useState(0);
  const [montoFinanciar, setMontoFinanciar] = useState(0);

  let cancelToken;

  /*--Declaration Errors
  -------Brandon Cantu*/
  const [errors, setErrors] = useState({
    Error_precio_vehiculo: false,
    Error_precio_seguro: false,
    Error_enganche: false,
    Error_plazos: false,
  });
  const [errorsMsg, setErrorsMsg] = useState({
    ErrorMsg_precio_vehiculo: "",
    ErrorMsg_precio_seguro: "",
    ErrorMsg_enganche: "",
    ErrorMsg_plazos: "",
  });

  /*--Declaration Constants
  -------Brandon Cantu*/
  const {
    precio_vehiculo = 275000,
    precio_seguro = 7500,
    // enganche = 7505.5,
    enganche = 9680.5,
    uso_auto = 0,
    tipo_auto = 0,
    plazos = 120,
  } = datosCotizar;

  // const whatsappUrl = `https://api.whatsapp.com/send?phone=+5218120854646&text=
  //   Buen%20d%C3%ADa,%20me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20esta%20cotizaci%C3%B3n%20
  //   con%20un%20auto%20${datosCotizarSend.Send_tipo_auto == 1 ? "Seminuevo" : "Nuevo"}%20de%20${formatNumberMoney(datosCotizarSend.Send_precio_vehiculo)},%20
  //   un%20seguro%20de%20${formatNumberMoney(datosCotizarSend.Send_precio_seguro)},%20
  //   para%20uso%20${datosCotizarSend.Send_uso_auto == 1 ? "Particular" : "Plataforma"}
  //   un%20plazo%20de%20${datosCotizarSend.Send_plazos}%20${datosCotizarSend.Send_uso_auto == 1 ? "quincenas" : "semanas"}
  //   y%20un%20desembolso%20inicial%20de%20${formatNumberMoney(Send_enganche)}
  // .`;

  /*FUNCIONES__________________
  ___________________________*/
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_COTIZADOR_URL +
          "/api/cotizador_web/get_desembolso_inicial",
        {
          valor_vehiculo: Number(cleanDataToSend(precio_vehiculo)),
          precio_seguro: Number(cleanDataToSend(precio_seguro)),
        }
      )
      .then((results) => {
        let status = results.status;
        let data = results.data;

        let enganche_inicial = 0;
        let maximo_enganche_inicial = Number(cleanDataToSend(precio_vehiculo));

        if (status === "200" || status === 200) {
          if (data.status === "success") {
            let result = data.data;
            enganche_inicial = Number(result.min_desembolso);
            maximo_enganche_inicial = Number(result.max_desembolso);
          } else {
            enganche_inicial = Number(precio_vehiculo) * 0.025 * 1.16 + 1488;
          }
          console.log(enganche_inicial)
          setDatosCotizar({ ...datosCotizar, enganche: enganche_inicial });
          setSend_enganche(enganche_inicial);
          setPoliticas({
            ...politicas,
            Politica_enganche: cleanDataToSend(enganche_inicial),
            Politica_max_enganche: cleanDataToSend(maximo_enganche_inicial),
          });
        }
      })
      .catch(function (error) {
        let enganche_inicial = Number(precio_vehiculo) * 0.025 * 1.16 + 1488;
        let maximo_enganche_inicial = Number(cleanDataToSend(precio_vehiculo));
        console.log(enganche_inicial)
        setDatosCotizar({ ...datosCotizar, enganche: enganche_inicial });
        setSend_enganche(cleanDataToSend(enganche_inicial));
        setPoliticas({
          ...politicas,
          Politica_enganche: cleanDataToSend(enganche_inicial),
          Politica_max_enganche: cleanDataToSend(maximo_enganche_inicial),
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calcularCredito();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCotizarSend, Send_enganche, errors.Error_precio_vehiculo]);

  useEffect(() => {
    updatePoliticas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datosCotizarSend.Send_precio_vehiculo,
    datosCotizarSend.Send_tipo_auto,
    datosCotizarSend.Send_uso_auto,
  ]);

  useEffect(() => {
    compareWithPoliticas(Send_enganche, "enganche");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [politicas, Send_enganche, datosCotizarSend.Send_precio_vehiculo]);
  useEffect(() => {
    compareWithPoliticas(
      datosCotizarSend.Send_precio_vehiculo,
      "precio_vehiculo"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosCotizarSend.Send_precio_vehiculo]);

  // FUNCTIONS FOR DATA ALTERAION***************************
  /**
   * It takes a string and removes any spaces, commas, and dollar signs.
   * @param value - the value of the input field
   * @returns the value that was passed in.
   */
  const cleanDataToSend = (value) => {
    if (typeof value === "string" && value.includes(" ")) {
      value = value.replace(" ", "");
    }
    if (typeof value === "string" && value.includes(",")) {
      value = value.replace(/,/g, "");
    }
    if (typeof value === "string" && value.includes("$")) {
      value = value.replace("$", "");
    }
    return value;
  };
  /**
   * It takes a number and returns a string with the number formatted as a currency
   * @param num - The number to be formatted.
   * @returns A function that takes a number and returns a formatted number.
   */
  const formatNumberMoney = (num) => {
    return Intl.NumberFormat("es-MX", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };
  /**
   * It takes a number of weeks or quincenas and returns a string with the number of years and months.
   * @param plazo - the number of payments
   * @param [unidad=1] - 1 = quincenas, 2 = semanas
   * @returns A string with the number of years and months.
   */
  const getYearsAndMonths = (plazo, unidad = 1) => {
    let yearsCount = plazo / 2 / 12;
    //quincenas
    if (unidad === 1) {
      yearsCount = plazo / 2 / 12;
    }
    //semanas
    else {
      yearsCount = plazo / 4.33 / 12;
    }
    let monthCount = yearsCount - Math.floor(yearsCount);
    let yearsInt = yearsCount - monthCount;
    let month = (monthCount * 12) / 1;
    let monthInt = 0;
    if(plazo === 208) {
      monthInt  = Math.floor(month);
    } else {
      monthInt = Math.ceil(month);
    }

    let yearString = " años";
    let monthString = " meses";

    if (monthInt === 1) {
      monthString = monthInt + " mes";
    }
    if (monthInt === 0) {
      monthString = "";
    }
    if (monthInt > 1) { 
      monthString = monthInt + " meses";
    }

    if (yearsInt === 1) {
      yearString = yearsInt + " año ";
    }
    if (yearsInt === 0) {
      yearString = "";
    }
    if (yearsInt > 1) {
      yearString = yearsInt + " años ";
    }

    return yearString + monthString;
  };
  /**
   * It compares the value of the input with the value of the policy, if the value of the input is less
   * than the value of the policy, it shows an error message.
   * @param newValue - The value that the user is typing in the input.
   * @param nameOfVariable - is the name of the variable that is being compared.
   */
  const compareWithPoliticas = (newValue, nameOfVariable) => {
    //DEFAULT MINIMOS///////////////////////////////////////////
    if (
      newValue < politicas["Politica_" + nameOfVariable] ||
      cleanDataToSend(newValue) < politicas["Politica_" + nameOfVariable]
    ) {
      let minValor = politicas["Politica_" + nameOfVariable];
      setErrors({ ...errors, ["Error_" + nameOfVariable]: true });
      setErrorsMsg({
        ...errorsMsg,
        ["ErrorMsg_" + nameOfVariable]:
          "El monto mínimo debe ser: $" + formatNumberMoney(minValor),
      });
    }
    //ENGANCHE///////////////////////////////////////////
    else if (
      nameOfVariable === "enganche" &&
      newValue > politicas.Politica_max_enganche
    ) {
      //SI EL ENGANCHE ES MAYOR AL MAX PEMITIDO
      setErrors({ ...errors, ["Error_" + nameOfVariable]: true });
      setErrorsMsg({
        ...errorsMsg,
        ["ErrorMsg_" + nameOfVariable]:
          "El máximo permitido es $" +
          formatNumberMoney(politicas.Politica_max_enganche),
      });
    }
    //PRECIO VEHICULO///////////////////////////////////////////
    else if (
      nameOfVariable === "precio_vehiculo" &&
      newValue > politicas.Politica_max_precio_vehiculo
    ) {
      //SI EL PRECIO DEL VEHICULO ES MAYOR AL MAX PEMITIDO
      setErrors({ ...errors, ["Error_" + nameOfVariable]: true });
      setErrorsMsg({
        ...errorsMsg,
        ["ErrorMsg_" + nameOfVariable]:
          "El máximo permitido es $" +
          formatNumberMoney(politicas.Politica_max_precio_vehiculo),
      });
    } else {
      setErrors({ ...errors, ["Error_" + nameOfVariable]: false });
      setErrorsMsg({ ...errorsMsg, ["ErrorMsg_" + nameOfVariable]: "" });
    }
  };
  // FUNCIONES PARA CALCULOS DE DATOS***********************
  /**
   * It's a function that makes an API call to a server, and if the server returns an error, it sets the
   * error message to "No Disponible".
   */
  const calcularCredito = async () => {
    //ENVIAMOS TRACKING INFO
    ReactGA.event({
      category: "Cotizador",
      action: "Calculo por cambio en input",
    });

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    if (errors.Error_precio_vehiculo) {
      setResultadoCalculo(false);
      setResultadoCalculoError("");
      return;
    }

    try {
      // setResultadoCalculo(false);
      setResultadoCalculoError("Cargando...");

      const results = await axios({
        method: "post",
        url:
          process.env.REACT_APP_API_COTIZADOR_URL +
          "/api/cotizador_web/calcular_cotizacion",
        data: {
          valor_vehiculo: Number(datosCotizarSend.Send_precio_vehiculo),
          enganche: Number(Send_enganche),
          seguro: Number(datosCotizarSend.Send_precio_seguro),
          plazos: datosCotizarSend.Send_plazos,
          tipo_auto: datosCotizarSend.Send_tipo_auto,
          uso_auto: datosCotizarSend.Send_uso_auto,
          origen: origen,
        },
        cancelToken: cancelToken.token,
      });

      console.log(results);

      let status = results.status;
      let data = results.data;

      if (status === "200" || status === 200) {
        if (data.status === "error_politicas") {
          setResultadoCalculo(false);
          setResultadoCalculoError("")
        } else if (data.status === "error") {
          setResultadoCalculo(false);
          setResultadoCalculoError("Consulta errónea");
        } else {
          let result = data.total_periodo;
          let formated_result = Intl.NumberFormat("es-MX", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(result);

          setResultadoCalculo(formated_result);
          setResultadoCalculoError("");
          setDosYMedioUno(data.dos_y_medio_a_uno);
          setMontoFinanciar(data.monto_a_financiar);
          if (
            datosCotizarSend.Send_uso_auto === 1 ||
            datosCotizarSend.Send_uso_auto === "1"
          ) {
            setPlazoText("Pago Quincenal");
          } else if (
            datosCotizarSend.Send_uso_auto === 2 ||
            datosCotizarSend.Send_uso_auto === "2"
          ) {
            setPlazoText("Pago Semanal");
          }
        }
      } else {
        setResultadoCalculo(false);
        setResultadoCalculoError("No Disponible");
      }
    } catch (error) {
      setResultadoCalculo(false);

      if (typeof error === "object") {
        if (error.constructor.name === "Cancel") {
          setResultadoCalculoError("Cargando...");
        } else {
          setResultadoCalculoError("No Disponible");
        }
      } else {
        setResultadoCalculoError("No Disponible");
      }
    }
  };

  const getMontoSeguro = (precio_vehiculo, uso = 1) => {
    if (uso === 2) return 16000.0;

    precio_vehiculo = Number(precio_vehiculo);

    if (precio_vehiculo <= 100000) {
      return 6000.0;
    } else if (precio_vehiculo > 100000 && precio_vehiculo <= 275000) {
      return 7500.0;
    } else if (precio_vehiculo > 275000 && precio_vehiculo <= 400000) {
      return 8000.0;
    } else if (precio_vehiculo > 400000) {
      return 12000.0;
    }
  };

  /**
   * It makes an API call to get the min and max down payment values, and if the current down payment
   * value is outside of that range, it sets the down payment value to the min or max value.
   */
  const updatePoliticas = async () => {
    try {
      const results = await axios({
        method: "post",
        url:
          process.env.REACT_APP_API_COTIZADOR_URL +
          "/api/cotizador_web/traer_politicas",
        data: {
          valor_vehiculo: Number(datosCotizarSend.Send_precio_vehiculo),
          enganche: Number(Send_enganche),
          seguro: Number(datosCotizarSend.Send_precio_seguro),
          plazos: datosCotizarSend.Send_plazos,
          tipo_auto: datosCotizarSend.Send_tipo_auto,
          uso_auto: datosCotizarSend.Send_uso_auto,
        },
        cancelToken: cancelToken.token,
      });

      console.log(results)

      // console.log(results)
      let status = results.status;
      let data = results.data;

      if (status === "200" || status === 200) {
        if (data.status === "success") {
          let politicas_traidas = data.data;
          console.log(politicas_traidas)
          if (
            politicas_traidas.min_desembolso ||
            politicas_traidas.max_desembolso
          ) {
            setPoliticas({
              ...politicas,
              Politica_enganche: cleanDataToSend(
                politicas_traidas.min_desembolso
              ),
              Politica_max_enganche: cleanDataToSend(
                politicas_traidas.max_desembolso
              ),
            });
            console.log(politicas_traidas.min_desembolso)
            if (politicas_traidas.min_desembolso > Send_enganche) {
              setDatosCotizar({
                ...datosCotizar,
                enganche: politicas_traidas.min_desembolso,
              });
              setSend_enganche(
                cleanDataToSend(politicas_traidas.min_desembolso)
              );
            } else if (politicas_traidas.max_desembolso < Send_enganche) {
              setDatosCotizar({
                ...datosCotizar,
                enganche: politicas_traidas.max_desembolso,
              });
              setSend_enganche(
                cleanDataToSend(politicas_traidas.max_desembolso)
              );
            }
          }
        } else {
        }
      }
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  };
  // INPUTS HANDLERS****************************************
  /**
   * It's a function that takes an event as an argument and then sets the state of the component.
   */
  const onInputChange = (e) => {
    setSliderChange(false);
    let isSectionPersonaVisible =
      document.getElementById("printData").style.opacity;
    if (isSectionPersonaVisible > "0") {
      document
        .querySelector("#printData")
        .classList.remove("areaDatosPersona_visible");
      document.getElementById("printData").style.opacity = 0.0;
      // document.querySelector("#sendPersonsInfo").innerHTML = "<span class='MuiButton-label'>Pedir información</span>";
    }
    let name = e.target.name;
    let valor = e.target.value;

    if (name === "precio_vehiculo") {
      // Se ajusta el seguro
      setDatosCotizar({
        ...datosCotizar,
        precio_vehiculo: valor,
        precio_seguro: formatNumberMoney(
          getMontoSeguro(cleanDataToSend(valor), datosCotizarSend.Send_uso_auto)
        ),
      });
      setDatosCotizarSend({
        ...datosCotizarSend,
        Send_precio_vehiculo: cleanDataToSend(valor),
        Send_precio_seguro: getMontoSeguro(
          cleanDataToSend(valor),
          datosCotizarSend.Send_uso_auto
        ),
      });
    } else if (name === "enganche") {
      setDatosCotizar({ ...datosCotizar, [name]: cleanDataToSend(valor) });
      setSend_enganche(cleanDataToSend(valor));
    } else if (name === "uso_auto") {
      // console.log(datosCotizarSend.precio_vehiculo);
      if (valor === 2) {
        setDatosCotizar({
          ...datosCotizar,
          [name]: valor,
          plazos: 208,
          precio_seguro: formatNumberMoney(
            getMontoSeguro(datosCotizarSend.Send_precio_vehiculo, valor)
          ),
        });
        setDatosCotizarSend({
          ...datosCotizarSend,
          ["Send_" + name]: valor,
          Send_plazos: 208,
          Send_precio_seguro: getMontoSeguro(
            datosCotizarSend.Send_precio_vehiculo,
            valor
          ),
        });
      } else {
        setDatosCotizar({
          ...datosCotizar,
          [name]: valor,
          plazos: 120,
          precio_seguro: formatNumberMoney(
            getMontoSeguro(datosCotizarSend.Send_precio_vehiculo, valor)
          ),
        });
        setDatosCotizarSend({
          ...datosCotizarSend,
          ["Send_" + name]: valor,
          Send_plazos: 120,
          Send_precio_seguro: getMontoSeguro(
            datosCotizarSend.Send_precio_vehiculo,
            valor
          ),
        });
      }
    } else {
      setDatosCotizar({ ...datosCotizar, [name]: valor });
      setDatosCotizarSend({
        ...datosCotizarSend,
        ["Send_" + name]: cleanDataToSend(valor),
      });
    }
  };

  /**
   * It takes an event object as an argument, and then it sets the state of the datosPersona object to
   * the current state of the datosPersona object, but with the value of the input field that was
   * changed.
   */
  const onInputChangePersona = (e) => {
    setDatosPersona({ ...datosPersona, [e.target.name]: e.target.value });
  };

  // SLIDERS HANDLER*****************************************
  const sliderCallHttpRequest = (eventSrcDesc, newValue) => {
    setSend_enganche(cleanDataToSend(newValue));
  };
  const [sliderStateDebounceCallHttpRequest] = React.useState(() =>
    debounce(sliderCallHttpRequest, 500, {
      leading: false,
      trailing: true,
    })
  );
  /**
   * It's a function that is called when a slider is moved. It sets the state of the slider value, and
   * then calls a debounced function that makes an API call.
   *
   * The debounced function is defined as follows:
   * @param e - event
   * @param newValue - The value of the slider
   */
  const handleSliderEngancheChangeUsingStateDebounce = (e, newValue) => {
    setSliderChange(true)
    newValue = Number(newValue);
    setDatosCotizar({ ...datosCotizar, enganche: newValue });

    if (
      newValue < politicas.Politica_enganche ||
      cleanDataToSend(newValue) < politicas.Politica_enganche
    ) {
      let minValor = politicas.Politica_enganche;
      setErrors({ ...errors, Error_enganche: true });
      setErrorsMsg({
        ...errorsMsg,
        ErrorMsg_enganche:
          "El monto mínimo debe ser: $" + formatNumberMoney(minValor),
      });
    } else {
      setErrors({ ...errors, Error_enganche: false });
      setErrorsMsg({ ...errorsMsg, ErrorMsg_enganche: "" });
    }
    // setAniosMeses(getYearsAndMonths(newValue));
    sliderStateDebounceCallHttpRequest("enganche", newValue);
  };
  // ********************************************************
  /**
   * It sends a POST request to a server, which then generates a PDF and sends it back to the client.
   * </code>
   * @returns The PDF is being returned.
   */
  const printCotizacion = () => {
    //Handling Errors.............
    let flag = false;
    for (const error in errors) {
      if (errors[error] === true) {
        flag = true;
      }
    }
    if (resultadoCalculoError === "No Disponible") flag = true;
    if (flag) {
      setResultadoCalculo(false);
      setResultadoCalculoError("");
      bcAlert({
        msg: {
          title: "Oops!",
          desc: "Es necesario cumplir con los montos señalados",
        },
        type: "error",
        styles: `margin-left: -1rem;`,
        options: false,
      });
      return;
    }

    //ENVIAMOS TRACKING INFO
    ReactGA.event({
      category: "Cotizador",
      action: "Imprimir cotizacion",
    });

    //SET LOADER
    let headerTitle = document.querySelector("#printCotizacion");
    let ogInner = headerTitle.innerHTML;
    let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
    headerTitle.innerHTML = loaderHtml;
    headerTitle.disabled = true;

    let nombre_persona = datosPersona.nombre_persona
      ? datosPersona.nombre_persona
      : "0";
    let auto = datosPersona.auto ? datosPersona.auto : "0";
    let correo = datosPersona.correo ? datosPersona.correo : "0";
    let celular = datosPersona.celular ? datosPersona.celular : "0";

    axios
      .post(
        process.env.REACT_APP_API_COTIZADOR_URL +
          "/api/cotizador_web/print_cotizacion_pdf",
        {
          valor_vehiculo: Number(datosCotizarSend.Send_precio_vehiculo),
          enganche: Number(Send_enganche),
          seguro: Number(datosCotizarSend.Send_precio_seguro),
          plazos: datosCotizarSend.Send_plazos,
          tipo_auto: datosCotizarSend.Send_tipo_auto,
          uso_auto: datosCotizarSend.Send_uso_auto,
          nombre_cliente: datosPersona.nombre_persona,
          auto: datosPersona.auto,
          correo: datosPersona.correo,
          celular: datosPersona.celular,
          origen: origen,
          origen_mail: origen_mail,
          pago_plazo: cleanDataToSend(resultadoCalculo),
          monto_a_financiar: cleanDataToSend(montoFinanciar),
          dos_y_medio_a_uno: dosYMedioUno,
        }
      )
      .then((response) => {
        console.log(response)
        if (response.data.status === "success") {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.target = "_system";
          a.style = "display: none";
          a.href =
            `${process.env.REACT_APP_API_COTIZADOR_URL}/api/cotizador_web/stream_cotizacion_pdf/` +
            Number(datosCotizarSend.Send_precio_vehiculo) +
            "/" +
            Number(Send_enganche) +
            "/" +
            Number(datosCotizarSend.Send_precio_seguro) +
            "/" +
            datosCotizarSend.Send_plazos +
            "/" +
            datosCotizarSend.Send_tipo_auto +
            "/" +
            datosCotizarSend.Send_uso_auto +
            "/" +
            nombre_persona +
            "/" +
            auto +
            "/" +
            correo +
            "/" +
            celular +
            "/" +
            origen +
            "/" +
            cleanDataToSend(resultadoCalculo) +
            "/" +
            cleanDataToSend(montoFinanciar) +
            "/" +
            dosYMedioUno;
          a.click();
        } else {
          bcAlert({
            msg: {
              title: "Oops!",
              desc: "No se pudo generar el pdf",
            },
            type: "error",
            styles: `margin-left: -1rem;`,
            options: false,
          });
        }
        headerTitle.innerHTML = ogInner;
        headerTitle.disabled = false;
      })
      .catch(function (error) {
        headerTitle.innerHTML = ogInner;
        headerTitle.disabled = false;

        bcAlert({
          msg: {
            title: "Oops!",
            desc: "Hubo un problema al descargar",
          },
          type: "error",
          styles: `margin-left: -1rem;`,
          options: false,
        });

        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  /**
   * It sends a POST request to an API endpoint, and if the response is successful, it changes the
   * button's text to "Information sent".
   * </code>
   * @returns the value of the variable "flag" which is a boolean.
   */
  const sendInfo = () => {
    let isSectionPersonaVisible =
      document.getElementById("printData").style.opacity;
    if (isSectionPersonaVisible === "0") {
      document
        .querySelector("#printData")
        .classList.add("areaDatosPersona_visible");
      document.getElementById("printData").style.opacity = 0.01;
      document.querySelector("#sendPersonsInfo").innerHTML =
        "<span class='MuiButton-label'>Solicitar información</span>";
    } else {
      if (datosPersona.nombre_persona === "") {
        bcAlert({
          msg: {
            title: "Oops!",
            desc: "Debes ingresar tu nombre",
          },
          type: "warning",
          styles: `margin-left: -1rem;`,
          options: false,
        });
        return false;
      } else if (datosPersona.auto === "" || datosPersona.auto.length < 2) {
        bcAlert({
          msg: {
            title: "Oops!",
            desc: "Debes ingresar un auto",
          },
          type: "warning",
          styles: `margin-left: -1rem;`,
          options: false,
        });
        return false;
      } else if (datosPersona.correo === "" || datosPersona.correo.length < 2) {
        bcAlert({
          msg: {
            title: "Oops!",
            desc: "Debes ingresar un correo válido",
          },
          type: "warning",
          styles: `margin-left: -1rem;`,
          options: false,
        });
        return false;
      } else if (
        datosPersona.celular === "" ||
        datosPersona.celular.length < 10
      ) {
        bcAlert({
          msg: {
            title: "Oops!",
            desc: "Debes ingresar un celular válido",
          },
          type: "warning",
          styles: `margin-left: -1rem;`,
          options: false,
        });
        return false;
      }

    //Handling Errors.............
    let flag = false;
    for (const error in errors) {
      if (errors[error] === true) {
        flag = true;
      }
    }
    if (resultadoCalculoError === "No Disponible") flag = true;
    if (flag) {
      setResultadoCalculo(false);
      setResultadoCalculoError("");
      bcAlert({
        msg: {
          title: "Oops!",
          desc: "Es necesario cumplir con los montos señalados",
        },
        type: "error",
        styles: `margin-left: -1rem;`,
        options: false,
      });
      return;
    }

      // ENVIAMOS TRACKING INFO
      ReactGA.event({
        category: "Cotizador",
        action: "Envío de info",
      });
      //SET LOADER
      let headerTitle = document.querySelector("#sendPersonsInfo");
      let ogInner = headerTitle.innerHTML;
      let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
      headerTitle.innerHTML = loaderHtml;
      headerTitle.disabled = true;

      axios
        .post(
          process.env.REACT_APP_API_COTIZADOR_URL +
            "/api/cotizador_web/enviar_informacion",
          {
            valor_vehiculo: Number(datosCotizarSend.Send_precio_vehiculo),
            enganche: Number(Send_enganche),
            seguro: Number(datosCotizarSend.Send_precio_seguro),
            plazos: datosCotizarSend.Send_plazos,
            tipo_auto: datosCotizarSend.Send_tipo_auto,
            uso_auto: datosCotizarSend.Send_uso_auto,
            nombre_cliente: datosPersona.nombre_persona,
            auto: datosPersona.auto,
            correo: datosPersona.correo,
            celular: datosPersona.celular,
            origen: origen,
            origen_mail: origen_mail,
            pago_plazo: cleanDataToSend(resultadoCalculo),
            monto_a_financiar: cleanDataToSend(montoFinanciar),
            dos_y_medio_a_uno: dosYMedioUno,
          }
        )
        .then((response) => {
          let data = response.data;
          // console.log(data);

          if (data.status === "success") {
            setInfoEnviada(true);
            bcAlert({
              msg: {
                title: "¡Gracias!",
                desc: "La información fue enviada a uno de nuestros asesores",
              },
              type: "success",
              styles: `margin-left: -1rem;`,
              options: false,
            });
            headerTitle.innerHTML =
              "<span class='MuiButton-label'>Información enviada</span>";
          } else {
            headerTitle.disabled = false;
            bcAlert({
              msg: {
                title: "Oops!",
                desc: "Hubo un problema al enviar la información, inténtelo más tarde",
              },
              type: "error",
              styles: `margin-left: -1rem;`,
              options: false,
            });
            headerTitle.innerHTML =
              "<span class='MuiButton-label'>Solicitar información</span>";
            headerTitle.disabled = false;
          }
        })
        .catch(function (error) {
          headerTitle.innerHTML = ogInner;
          headerTitle.disabled = false;

          bcAlert({
            msg: {
              title: "Oops!",
              desc: "Hubo un problema en el envío de la información, inténtelo más tarde",
            },
            type: "error",
            styles: `margin-left: -1rem;`,
            options: false,
          });

          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  /**
   * When the submit button is clicked, prevent the default action, and then call the sendInfo function.
   */
  const submitHandle = (e) => {
    e.preventDefault();
    sendInfo();
  };

  return (
    <form id={"cotizadorForm"} onSubmit={e => submitHandle(e)}>
      <div className='papel_Cotizador' elevation={10} style={paperStyle}>

        <HeaderCotizador resultado={resultadoCalculo} message={resultadoCalculoError} plazoText={plazoText}/>

        <div className='rowArea'>
          <RowInput
            groupWidth={"50%"} label={"Tipo de auto"}
            content={
              <FormControl className='form-control selectForm'>
                <Select
                  value={tipo_auto}
                  onChange={e => onInputChange(e)}
                  inputProps={{
                    name: 'tipo_auto',
                    id: 'tipo_auto',
                    className: "selectInputCotizador",
                  }}
                >
                  <MenuItem value={1}>Nuevo</MenuItem>
                  <MenuItem value={2}>Seminuevo</MenuItem>
                </Select>
              </FormControl>
            }
          />
          <RowInput
            groupWidth={"50%"} label={"Uso de  auto"}
            content={
              <FormControl className='form-control selectForm'>
                <Select
                  value={uso_auto}
                  onChange={e => onInputChange(e)}
                  inputProps={{
                    name: 'uso_auto',
                    id: 'uso_auto',
                    className: "selectInputCotizador",
                  }}
                >
                  <MenuItem value={1}>Particular</MenuItem>
                  <MenuItem value={2}>Plataforma</MenuItem>
                </Select>
              </FormControl>
            }
          />
          <RowInput
            groupWidth={"40%"} label={"Plazo"}
            content={
              <FormControl className='form-control selectForm'>
                <Select
                  value={plazos}
                  onChange={e => onInputChange(e)}
                  inputProps={{
                    name: 'plazos',
                    id: 'plazos',
                    className: "selectInputCotizador",
                  }}
                >
                  {
                    //Particular
                    datosCotizarSend.Send_uso_auto === 1 ?
                      [
                        <MenuItem key={120} value={120}>{getYearsAndMonths(120,1)}</MenuItem>,
                        <MenuItem key={96} value={96}>{getYearsAndMonths(96,1)}</MenuItem>,
                        <MenuItem key={72} value={72}>{getYearsAndMonths(72,1)}</MenuItem>,
                        // <MenuItem key={48} value={48}>{getYearsAndMonths(48,1)}</MenuItem>,
                        // <MenuItem key={24} value={24}>{getYearsAndMonths(24,1)}</MenuItem>,
                        // <MenuItem key={12} value={12}>{getYearsAndMonths(12,1)}</MenuItem>
                      ]
                      :
                      [
                        <MenuItem key={208} value={208}>{getYearsAndMonths(208,2)}</MenuItem>,
                        // <MenuItem key={104} value={104}>{getYearsAndMonths(104,2)}</MenuItem>,
                        // <MenuItem key={52} value={52}>{getYearsAndMonths(52,2)}</MenuItem>
                      ]
                  }
                </Select>
              </FormControl>
            }
          />
        </div>

        <div className='rowArea'>
          <RowInput type={"text"} label={"Precio Vehiculo"} placeholder={"Ingrese el precio del seguro"}
            groupWidth={"50%"} adornment={"$"} classes="input-money" requiered={true} numberFormat={true}
            id={"precio_vehiculo"} value={datosCotizarSend.Send_precio_vehiculo}
            onChange={onInputChange}
            error={errors.Error_precio_vehiculo} helperText={errorsMsg.ErrorMsg_precio_vehiculo}
          />
          <RowInput type={"text"} label={"Seguro Estimado"} placeholder={"Ingrese el precio del seguro"}
            groupWidth={"50%"} adornment={"$"} classes="input-money" requiered={true} numberFormat={true} disabled={true}
            id={"precio_seguro"} value={precio_seguro}
            onChange={onInputChange}
            error={errors.Error_precio_seguro} helperText={errorsMsg.ErrorMsg_precio_seguro}
          />
        </div>

        <SliderInput
          sliderChange={sliderChange}
          label={"¿Cuánto quieres que sea tu desembolso inicial?"}  
          value={cleanDataToSend(enganche)}
          minVal={politicas.Politica_enganche}
          maxVal={politicas.Politica_max_enganche}
          onTextChange={onInputChange}
          onSliderChange = {handleSliderEngancheChangeUsingStateDebounce}
          id={"enganche"}
          showTextInput = {true}
          error={errors.Error_enganche}
          helperText={errorsMsg.ErrorMsg_enganche}
          classes={""}
          numberFormat = {false}
          disabled={false}
          marks={[
            {
              value: politicas.Politica_enganche,
              label: '$'+formatNumberMoney(politicas.Politica_enganche),
            },
            {
              value: politicas.Politica_max_enganche,
              label: '$'+formatNumberMoney(politicas.Politica_max_enganche),
            },
          ]}
        /> 

        <Button type='button' onClick={(e) => printCotizacion()} id="printCotizacion" color='secondary' variant="contained" style={btnstyle} fullWidth>Imprimir Cotización</Button>

        <div className='printData' id='printData' style={{ display: "none", opacity: "0", marginTop: "-10rem",marginBottom: "1rem" }}>
          <RowInput type={"text"} label={"Tu nombre *"} placeholder={"Ingrese su nombre"}
            groupWidth={"100%"} adornment={""} requiered={false}
            id={"nombre_persona"} value={datosPersona.nombre_persona}
            onChange={onInputChangePersona}
            error={false} helperText={""}
          />
          <RowInput type={"tel"} label={"Celular *"} placeholder={"Ingrese su teléfono"}
            groupWidth={"100%"} adornment={""} requiered={false}
            id={"celular"} value={datosPersona.celular}
            onChange={onInputChangePersona}
            error={false} helperText={""}
          /> 
          <RowInput type={"email"} label={"Correo *"} placeholder={"Ingrese su email"}
              groupWidth={"100%"} adornment={""} requiered={false}
              id={"correo"} value={datosPersona.correo}
              onChange={onInputChangePersona}
              error={false} helperText={""}
          />
          <RowInput type={"text"} label={"Auto"} placeholder={"Marca, modelo y año"}
            groupWidth={"100%"} adornment={""} requiered={false}
            id={"auto"} value={datosPersona.auto}
            onChange={onInputChangePersona}
            error={false} helperText={""}
          />
        </div>

        <div className="rowArea">
          <div className="rowGroup" style={{width: "80%"}}>
            <Button type='submit' id="sendPersonsInfo" color='primary' variant="contained" style={btnstyle} className={infoEnviada ? "disableButton" : ""} fullWidth>Me interesa</Button>
          </div>
          <div className="rowGroup" style={{width: "20%"}}>
            <a target="_blank"  
                rel="noopener noreferrer" 
                href={`https://api.whatsapp.com/send?phone=+5218120854646&text=Buen%20d%C3%ADa,%20quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n%20de%20una%20cotizaci%C3%B3n%20de%20%20Auto%20${datosCotizarSend.Send_tipo_auto === 2 ? "Seminuevo" : "Nuevo"}%20de%20%20$%20${formatNumberMoney(datosCotizarSend.Send_precio_vehiculo)},%20con%20un%20seguro%20estimado%20de%20$%20${formatNumberMoney(datosCotizarSend.Send_precio_seguro)},%20para%20uso%20${datosCotizarSend.Send_uso_auto === 1 ? "Particular" : "Plataforma"},%20con%20un%20plazo%20de%20${datosCotizarSend.Send_plazos}%20${datosCotizarSend.Send_uso_auto === 1 ? "quincenas" : "semanas"}%20y%20un%20desembolso%20inicial%20de%20$%20${formatNumberMoney(Send_enganche)}.`} className="whatsapp-btn">
                <ImWhatsapp/>
            </a>
          </div>
        </div>

        <div className="cotizador_info">
          <span>Esta cotización es sólo para fines informativos, para más información por favor contáctanos a través del chat o formulario.</span>
        </div>
      </div>
    </form>
  );
};

export default Calculadora;
