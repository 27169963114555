import React, { useEffect, useState } from 'react'
import './Cuenta.css';
import axios from 'axios';
//icons
import {FaUserCircle} from 'react-icons/fa';
import {MdLock} from 'react-icons/md';
// import {MdPhoneInTalk} from 'react-icons/md';
import ConfCard from './ConfCard.js/ConfCard';
import {IconButton} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {bcAlert} from '../../../../boclAlert';
import ReactGA from 'react-ga';
 
const Cuenta=()=>{

    const [showPassword, setShowPassword] = useState({
        password_actual: false,
        password_nueva: false,
        password_confirm: false
    });
    const handleClickShowPassword = (objectElement) =>{
        setShowPassword({ ...showPassword, [objectElement]: !showPassword[objectElement] });
    }
    const handleMouseDownPassword = (objectElement) =>{
        setShowPassword({ ...showPassword, [objectElement]: !showPassword[objectElement] });
    }
    /**SANITIZE************** */
    const sanitize = (string) => {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            "/": '&#x2F;',
            "`": '&grave;',
        };
        const reg = /[&<>"'/`]/ig;
        return string.replace(reg, (match)=>(map[match]));
    }

    /*--Declaration of INPUTS VARIABLES
    ---------correo (string)
    ---------password (string)
    ---------user (object)
    -------Brandon Cantu
    ------------*/
    const [nombreUsuario, setNombreUsuario] = useState("");
    const [password, setPassword] = useState({
        password_actual: "",
        password_nueva: "",
        password_confirm: ""
    });

    // ACTUALIZAAR DATOS DE CONTACTO
    const [contacto, setContacto] = useState({
        correo: "",
        telefono: ""
    })

    useEffect(() => {
        getDatosContacto();
    }, [])

    /*--Funciones para asignar valores
    ---------message (strimg) 
    ---------token (string)
    -------Brandon Cantu
    ------------*/
    const onInputChangeNombre = e => {
        // console.log(e.target.name)
        setNombreUsuario(e.target.value);
    };
    const onInputChangePassword = e => {
        // console.log(e.target.value)
        setPassword({ ...password, [e.target.name]: e.target.value });
    };

    /*--Funciones API Las Opciones
    ---------message (strimg) 
    ---------token (string)
    -------Brandon Cantu
    ------------*/
    const cambiarNombre = () =>{
        let editar_nombre = sanitize(nombreUsuario);
        if(editar_nombre === ''){
            // alert('Introduce un nombre por favor')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Introduce un nombre por favor"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }
    
        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Cambiar Nombre'
        });
        //DESABILITAMOS EL BOTON
        let btn = document.getElementById("guardarNombre");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled")
        //SET LOADER
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;

        let json = JSON.stringify({"editar_nombre":editar_nombre});
        
        // console.log(json)
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/cambiar_nombre",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            let result = response.data;
            // console.log(result)
            if (result.status === 'success') {
                // alert("Hola "+editar_nombre+"!");
                bcAlert({
                    msg : {
                        "title":"Listo!", 
                        "desc":"Hola "+editar_nombre+"!"
                    },
                    type :"success", 
                    styles : ``, 
                    options : false
                });
                document.getElementById("navBar_nombre_usuario").innerHTML = "Hola "+editar_nombre+"!";

            }else if(result.status === 'error_token'){
                // alert("Ya cuentas con este contrato")
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"La sesión caducó"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else{
                // alert("Hubo un error al agregar el contrato!")
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Hubo un error al agregar el contrato!"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }
        })
        .catch(function (error) {
            
            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            // alert("Hubo un problema al consultar la información");
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Hubo un problema al consultar la información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });

            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    const cambiarPassword = () =>{
        let editar_password_actual = password.password_actual;
        let editar_password_nueva = password.password_nueva;
        let editar_password_confirm = password.password_confirm;

        if(editar_password_actual === '' || editar_password_actual.length < 8){
            // alert('Introduce tu contraseña actual')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Introduce tu contraseña actual"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }else 
        if(editar_password_nueva === '' || editar_password_nueva.length < 8){
            // alert('Introduce una contraseña de 8 carácteres')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Introduce una contraseña de 8 carácteres"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }else
        if(editar_password_confirm === '' || editar_password_confirm.length < 8){
            // alert('Debes confirmar tu contraseña')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Debes confirmar tu contraseña"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }else
        if (editar_password_nueva !== editar_password_confirm) {
            // alert('La contrasenña nueva no coincide!')
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"La contrasenña nueva no coincide!"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
            return false;
        }

        //ENVIAMOS TRACKING INFO
        ReactGA.event({
            category: 'Boton Portal',
            action: 'Cambiar Contraseña'
        });
        //DESABILITAMOS EL BOTON
        let btn = document.getElementById("guardarContrasena");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled")

        //SET LOADER
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;


        let json = JSON.stringify({"password_actual":editar_password_actual, "password_nueva":editar_password_nueva});
        // console.log(json)
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_API_DM_URL+"/api/cambiar_password",{"json":json}, {
            headers: {
            'Authorization' : token,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            let result = response.data;
            // console.log(result)
            if (result.status === 'success') {
                // alert('La contraseña se modificó correctamente')
                bcAlert({
                    msg : {
                        "title":"Listo!", 
                        "desc":"La contraseña se modificó correctamente"
                    },
                    type :"success", 
                    styles : ``, 
                    options : false
                });
                document.getElementById("password_actual").value = "";
                document.getElementById("password_nueva").value = "";
                document.getElementById("password_confirm").value = "";
            }else if(result.status === 'error_password_incorrecto'){
                // alert('La contraseña actual es incorrecta');
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"La contraseña actual es incorrecta"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else if(result.status === 'error_password_similar'){
                // alert('La contraseña ya se ha usado anteriormente');
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"La contraseña ya se ha usado anteriormente"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else if(result.status === 'error_token'){
                // alert('La sesión caducó');
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"La sesión caducó"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }else{
                // alert('Ocurrió un problema al intentar cambiar tu contraseña');
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Ocurrió un problema al intentar cambiar tu contraseña"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            }
        })
        .catch(function (error) {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            // alert("Hubo un problema al consultar la información");
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Hubo un problema al consultar la información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });

            if (error.response) {
            // Request made and server responded
            console.log("Error-Response: "+error.response.data);
            console.log("Error-Status: "+error.response.status);
            console.log("Error-Headers: "+error.response.headers);
            } else if (error.request) {
            // The request was made but no response was received
            console.log('Error-Request: '+error.request);
            } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error-Message: '+ error.message);
            }
        });
    }

    const getDatosContacto = async () => {
        const userIdentity = JSON.parse(localStorage.getItem("userIdentity"));

        if(userIdentity.id) {
            axios.post(
                process.env.REACT_APP_API_DM_URL+"/api/get_dato_contacto_extra",
                {
                    "id_usuario": userIdentity.id.toString()
                }, 
                {
                headers: {
                'Authorization' : localStorage.getItem('token'),
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if(response?.data?.code === 200) {
                    const { telefono, email } = response.data.data;
                    if(email) {
                        setContacto(prev => ({
                            ...prev,
                            correo: email
                        }))
                    }
                    if(telefono) {
                        setContacto(prev => ({
                            ...prev,
                            telefono: telefono
                        }))
                    }
                }
            })
            .catch(function (error) {    
                // alert("Hubo un problema al consultar la información");
                bcAlert({
                    msg : {
                        "title":"Oops!", 
                        "desc":"Hubo un problema al consultar la información"
                    },
                    type :"error", 
                    styles : ``, 
                    options : false
                });
            });
        }
    }

    const actualizarDatos = async () => {
        const userIdentity = JSON.parse(localStorage.getItem("userIdentity"));
        const { correo, telefono } = contacto;

        // Validar celular
        if(telefono.length !== 10) {
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"El celular debe contener 10 digitos"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return;
        }

        // Validar correo
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(correo)) {
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Ingrese un correo electrónico válido"
                },
                type :"warning", 
                styles : ``, 
                options : false
            });
            return false;
        }

        let btn = document.getElementById("guardarDatosContacto");
        btn.disabled = true;
        btn.classList.add("btn-blue-disabled")

        //SET LOADER
        //disapper content
        let ogInner = btn.innerHTML;
        let loaderHtml = `<img class="loopLoader_blue boclLoader" src="${process.env.PUBLIC_URL}/svg/loopLoader.svg"></img>`;
        btn.innerHTML = loaderHtml;

        axios.post(
            process.env.REACT_APP_API_DM_URL+"/api/confirmar_datos_contacto",
            {
                "id_usuario": userIdentity.id.toString(),
                "telefono": telefono,
                "correo": correo
            }, 
            {
            headers: {
            'Authorization' : localStorage.getItem('token'),
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
            }
        })
        .then(response => {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            let result = response.data;
            if (result.status === 'success') {
                bcAlert({
                    msg : {
                        "title":"Listo!", 
                        "desc":"Los datos se actualizaron correctamente"
                    },
                    type :"success", 
                    styles : ``, 
                    options : false
                });
            }
        })
        .catch(function (error) {

            btn.disabled = false;
            btn.classList.remove("btn-blue-disabled")
            btn.innerHTML = ogInner;

            // alert("Hubo un problema al consultar la información");
            bcAlert({
                msg : {
                    "title":"Oops!", 
                    "desc":"Hubo un problema al actualizar la información"
                },
                type :"error", 
                styles : ``, 
                options : false
            });
        });
    }

    return(
        <div className="float-left-cont">
            {/* <ConfCard 
                titulo={"Nombre Usuario"}
                icon = {<FaUserCircle className="opcion-conf-icon"/>} 
                content={
                    <div className="form">
                        <div className="groupCuenta">
                            <label>Cambia tu nombre</label>
                            <div className="form-group">
                                <input type="text" name="nombre" id="nombre" onChange={(evt) => onInputChangeNombre(evt)} maxLength={50} placeholder={"Nuevo nombre"}></input>
                            </div>
                        </div>
                        <button className="btn-blue" id="guardarNombre" onClick={() => {cambiarNombre()}}>
                            GUARDAR
                        </button>
                    </div>
                }
            /> */}

            <ConfCard 
                titulo={"Datos de contacto"}
                icon = {<FaUserCircle className="opcion-conf-icon"/>} 
                content={
                    <div className="form">
                        <div className="groupCuenta">
                            <label>Celular</label>
                            <div className="form-group">
                                <input 
                                    type="tel" 
                                    name="celular" 
                                    id="txtCel" 
                                    value={contacto.telefono}
                                    onChange={e => setContacto(prev => ({
                                        ...prev,
                                        telefono: e.target.value
                                    }))} 
                                    maxLength={10} 
                                    placeholder={"Celular"}/>
                            </div>
                        </div>
                        <div className="groupCuenta">
                            <label>Correo electrónico</label>
                            <div className="form-group">
                                <input 
                                    type="email" 
                                    name="correo" 
                                    id="txtCorreo" 
                                    value={contacto.correo}
                                    onChange={e => setContacto(prev => ({
                                        ...prev,
                                        correo: e.target.value
                                    }))} 
                                    maxLength={50} 
                                    placeholder={"Correo electrónico"}/>
                            </div>
                        </div>
                        <button className="btn-blue" id="guardarDatosContacto" onClick={actualizarDatos}>
                            GUARDAR
                        </button>
                    </div>
                }
            />

            <ConfCard 
                titulo={"Editar Contraseña"}
                icon = {<MdLock className="opcion-conf-icon"/>} 
                content={
                    <div className="form">
                        {/* contraseña actual */}
                        <div className="groupCuenta">
                            <label>Contraseña actual</label>
                            <div className="form-group input-password">
                                <input type={showPassword.password_actual ? "text" : "password"} name="password_actual" id="password_actual" onChange={(evt) => onInputChangePassword(evt)} minLength={8} maxLength={30} placeholder={"Contraseña"}></input>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {handleClickShowPassword("password_actual")}}
                                    onMouseDown={() => {handleMouseDownPassword("password_actual")}}
                                    >
                                    {showPassword.password_actual ? <Visibility style={{width: "100%", marginRight:"-.1rem"}}/> : <VisibilityOff style={{width: "100%", marginRight:"-.1rem"}}/>}
                                </IconButton>
                            </div>
                        </div>
                        {/* contraseña nueva */}
                        <div className="groupCuenta">
                            <label>Nueva contraseña</label>
                            <div className="form-group input-password">
                                <input type={showPassword.password_nueva ? "text" : "password"} name="password_nueva" id="password_nueva" onChange={(evt) => onInputChangePassword(evt)} minLength={8} maxLength={30} placeholder={"Nueva contraseña"}></input>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {handleClickShowPassword("password_nueva")}}
                                    onMouseDown={() => {handleMouseDownPassword("password_nueva")}}
                                    >
                                    {showPassword.password_nueva ? <Visibility style={{width: "100%", marginRight:"-.1rem"}}/> : <VisibilityOff style={{width: "100%", marginRight:"-.1rem"}}/>}
                                </IconButton>
                            </div>
                        </div>
                        {/* contraseña confirmar */}
                        <div className="groupCuenta">
                            <label>Confirma tu nueva contraseña</label>
                            <div className="form-group input-password">
                                <input type={showPassword.password_confirm ? "text" : "password"} name="password_confirm" id="password_confirm" onChange={(evt) => onInputChangePassword(evt)} minLength={8} maxLength={30} placeholder={"Nueva contraseña"} ></input>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {handleClickShowPassword("password_confirm")}}
                                    onMouseDown={() => {handleMouseDownPassword("password_confirm")}}
                                    >
                                    {showPassword.password_confirm ? <Visibility style={{width: "100%", marginRight:"-.1rem"}}/> : <VisibilityOff style={{width: "100%", marginRight:"-.1rem"}}/>}
                                </IconButton>
                            </div>
                        </div>
                        <button className="btn-blue" id="guardarContrasena" onClick={() => {cambiarPassword()}}>
                            AGREGAR
                        </button>
                    </div>
                }
            />

            {/* <ConfCard 
                titulo={"Numero Teléfono"}
                icon = {<MdPhoneInTalk className="opcion-conf-icon"/>} 
                content={
                    <div className="form">
                        <div className="groupCuenta">
                            <label>Cambia tu teléfono</label>
                            <div className="form-group">
                                <input type="number" name="telefono" maxLength={10} placeholder={"Nuevo numero celular"}></input>
                            </div>
                        </div>
                        <button className="btn-blue">
                            AGREGAR
                        </button>
                    </div>
                }
            /> */}
            
        </div>     
        
    )
}
 
export default Cuenta